<template>
  <div id="eventPopup" v-scroll="throttle(onScroll,300)">
    <q-dialog v-model="isShow"
              position="bottom"
              seamless
              transition-hide="false">
      <q-card
          id="popupCard"
          :class="$attrs.platform === 'mobile'
                ? 'q-mb-sm'
                : 'q-mb-lg'"
          class="row no-wrap no-shadow items-center q-mx-xs hide-scrollbar no-scroll popup absolute-bottom-center">
        <div :class="event.img"
             class="col-3 q-pa-sm"
             @click="openDetailPopup">
          <q-img no-spinner
                 src="@/assets/images/img_eventPopup.png"/>
        </div>
        <q-btn :ripple="false"
               class="row items-center full-height no-margin no-padding"
               flat
               @click="openDetailPopup">
          <div class="column q-pr-sm">
            <p class="text-caption_large no-scroll hide-scrollbar overflow-hidden text-left no-margin no-padding wrap ellipsis-2-lines"
               style="line-height: 1.4;"
               v-html="events.title"
            >
            </p>
          </div>
        </q-btn>
        <q-space/>
        <q-separator color="grey-3"
                     inset
                     vertical/>
        <div :class="event.closeBtn"
             class="col-2 no-border-radius full-height column justify-center items-center q-mx-auto">
          <q-btn :ripple="false"
                 dense
                 flat
                 @click="closePopup">
            <p class="text-caption_large no-margin">
              {{ $attrs.lang.close }}
            </p>
          </q-btn>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { throttle } from 'quasar'
import { computed, reactive } from 'vue'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const events = reactive(computed(() => store.getters.getEvent))
const isShow = reactive(computed(() => store.getters.getEventPopup))

// Methods

const closePopup = () => {
  localStorage.setItem('eventPopup', events.value.id)
  store.commit('showEventPopup', false)
}

const onScroll = (y) => {
  y > 600 || events.value.id === undefined
    ? store.commit('showEventPopup', false)
    : events.value.id.toString() !== localStorage.getItem('eventPopup')
      ? store.commit('showEventPopup', true)
      : store.commit('showEventPopup', false)
}

const openDetailPopup = () => store.commit('showEventDetailPopup', true)

// Lifecycles

</script>

<style module="event">

.img {
  width: 60px !important;
}

.closeBtn {
  min-width: 40px
}
</style>

<style scoped>

.popup {
  width: 100vw;
  height: 100vh;
  cursor: pointer;
  overflow: visible;
  max-height: 64px !important;
  max-width: 360px !important;
  border-radius: 8px !important;
  box-shadow: 0 6px 12px rgba(0, 0, 0, .2) !important;
}
</style>
