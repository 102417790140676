<template>
  <div id="bodySubIntroduction"
       :class="$attrs.platform === 'mobile'
       ? 'column reverse q-mb-xl'
       : 'row'"
       class="layout full-width relative-position">
    <div :class="$attrs.platform === 'mobile'
          ? 'justify-center q-px-md'
          : 'q-pr-lg q-pl-md justify-end'"
         class="col-6 row q-pb-xl">
      <q-video :class="introduction.video"
               :src="youtubeURL"
               tabindex="-1"
               @mousedown="logEvent('action_section1_video')"
               />
    </div>
    <div :class="$attrs.platform === 'mobile'
          ? ''
          : 'q-pr-md'" class="column no-margin col-6 q-pt-lg q-pb-xl">
      <p v-for="(text, idx) in $attrs.lang.titles"
         :key="idx"
         :class="$attrs.platform === 'mobile'
          ? 'row justify-center text-center items-center text-body_large_bold'
          : 'row items-start text-title_normal_bold q-pl-lg'"
         class="slogans text-color_lightgray2 no-margin">
        {{ text }}
      </p>
      <q-btn :class="$attrs.platform === 'mobile'
              ? 'text-center'
              : 'text-left q-pl-lg'"
             class="text-color_vitoBlue text-body_normal_bold q-pt-md"
             dense
             flat
             no-ripple
             tabindex="7"
             @click="appDownload('android')"
             @mousedown="logEvent('action_section1_button_google')"
             v-html="$attrs.lang.download"/>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { titlesMotion } from '@/utils/introduction'
import { onMounted } from 'vue'
import { appDownload } from '../utils/appDownlaod'
import { logEvent } from '../utils/amplitude'

// Props

// Emits

// Variables

const store = useStore()
const pageMaxHeight = store.getters.getSubIntroPageMaxHeight + 'px'
const youtubeURL = store.getters.getSubIntroYoutubeURL

// Responses

// Methods

// Lifecycles

onMounted(() => titlesMotion())

</script>

<style module="introduction">

.video {
  width: 100%;
  max-width: 380px;
  height: 100%;
  max-height: 247px;
  margin-top: 4px;
  z-index: 22;
}

</style>

<style scoped>
.layout {
  width: 100%;
  height: v-bind(pageMaxHeight);
  padding: 0;
  opacity: 1;
}
</style>
