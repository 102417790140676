import { eqIncrease } from '@/utils/eqMotion'

export const defaultHeight = (nodes, selection) => {
  const childNode = nodes[selection].firstChild.style
  const parentNode = nodes[selection].parentNode
  childNode.marginLeft = '-90px'
  childNode.width = '180%'
  parentNode.getElementsByTagName('div')[0].style.display = 'block'
  // parentNode.getElementsByTagName('img')[1].style.display = 'block'
  parentNode.getElementsByTagName('p')[0].style.display = 'block'
  parentNode.getElementsByTagName('strong')[0].style.display = 'block'
}

export const increaseHeight = (i, selection, responseEl, extraEl) => {
  i = (i - selection) + 2
  eqIncrease(i, selection, responseEl, extraEl)

  const firstChild = responseEl[i].firstChild.style
  const parentNode = responseEl[i].parentNode
  firstChild.width = '180%'
  firstChild.marginLeft = '-90px'
  parentNode.style.cursor = 'pointer'
  parentNode.getElementsByTagName('div')[0].style.display = 'block'
  // parentNode.getElementsByTagName('img')[1].style.display = 'block'
  parentNode.getElementsByTagName('p')[0].style.display = 'block'
  parentNode.getElementsByTagName('strong')[0].style.display = 'block'
}

export const decreaseHeight = (responseEl) => {
  responseEl.forEach(el => {
    el.parentNode.getElementsByTagName('div')[0].style.display = 'none'
    // el.parentNode.getElementsByTagName('img')[1].style.display = 'none'
    el.parentNode.getElementsByTagName('p')[0].style.display = 'none'
    el.parentNode.getElementsByTagName('strong')[0].style.display = 'none'
    el.firstChild.style.width = '150%'
    el.firstChild.style.marginLeft = '-50px'
  })
}
