import openURL from 'quasar/src/utils/open-url.js';

const googleAppUrl =
  'https://vitoapp.onelink.me/bbkt/2338306c'

export const appDownload = (platform) => {
  if (platform === 'android') {
    const googleAppURL = googleAppUrl
    openURL(googleAppURL)

    window.AF &&
      window.AF('pba', 'event', {
        eventType: 'EVENT',
        eventName: 'app_download',
        eventValue: { platform: 'android' }
      })
    window.kakaoPixel && window.kakaoPixel.participation && window.kakaoPixel.participation()
    window.fbq && window.fbq('trackCustom', 'app_download', { platform: 'android' })
    window.wcs_do_wrapper && window.wcs_do_wrapper('4', '1')
  } else {
    throw new Error(`${platform}은 지원하지 않습니다.`)
  }
}
