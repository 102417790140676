<template>
  <div v-if="$attrs.platform !== 'mobile'"
       id="bodySubFeatures"
       :class="features.layout"
       class="relative-position">
    <div class="full-width full-height">
      <div class="full-width row chapter">
        <div id="feature_mov_1_container"
             class="col-6 row justify-center">
          <video id="feature_mov_1"
                 :class="features.video"
                 class="video"
                 loop
                 muted
                 playsinline
                 preload="auto">
            <source src="@/assets/video/vid_body_feature1.mp4"
                    type="video/mp4">
          </video>
        </div>
        <div id="feature_mov_1_text_container"
             class="col-6 row justify-center">
          <body-sub-features-template id="feature_mov_1_text"
                                      :lang="$attrs.lang.chapter1"
                                      :platform="$attrs.platform"/>
        </div>
      </div>
      <div class="full-width row chapter">
        <div id="feature_mov_2_text_container"
             class="col-6 row justify-center">
          <body-sub-features-template id="feature_mov_2_text"
                                      :lang="$attrs.lang.chapter2"
                                      :platform="$attrs.platform"/>
        </div>
        <div id="feature_mov_2_container"
             class="col-6 row justify-center">
          <video id="feature_mov_2"
                 :class="features.video"
                 class="video"
                 loop
                 muted
                 playsinline
                 preload="auto">
            <source src="@/assets/video/vid_body_feature2.mp4"
                    type="video/mp4">
          </video>
        </div>
      </div>
      <div class="full-width row chapter">
        <div id="feature_mov_3_container"
             class="col-6 row justify-center">
          <video id="feature_mov_3"
                 :class="features.video"
                 class="video"
                 loop
                 muted
                 playsinline
                 preload="auto">
            <source src="@/assets/video/vid_body_feature3.mp4"
                    type="video/mp4">
          </video>
        </div>
        <div id="feature_mov_3_text_container"
             class="col-6 row justify-center">
          <body-sub-features-template id="feature_mov_3_text"
                                      :lang="$attrs.lang.chapter3"
                                      :platform="$attrs.platform"/>
        </div>
      </div>
      <div class="full-width row chapter">
        <div id="feature_mov_4_text_container"
             class="col-6 row justify-center relative-position">
          <body-sub-features-template id="feature_mov_4_text"
                                      :lang="$attrs.lang.chapter4"
                                      :platform="$attrs.platform"/>
        </div>
        <div id="feature_mov_4_container"
             class="col-6 row justify-center">
          <video id="feature_mov_4"
                 :class="features.video"
                 class="video"
                 loop muted playsinline
                 preload="auto">
            <source src="@/assets/video/vid_body_feature4.mp4"
                    type="video/mp4">
          </video>
        </div>
      </div>
      <div class="full-width row chapter">
        <div id="feature_mov_5_container"
             class="col-6 row justify-center">
          <video id="feature_mov_5"
                 :class="features.video"
                 class="video"
                 loop
                 muted
                 playsinline
                 preload="auto">
            <source src="@/assets/video/vid_body_feature5.mp4"
                    type="video/mp4">
          </video>
        </div>
        <div id="feature_mov_5_text_container"
             class="col-6 row justify-center">
          <body-sub-features-template id="feature_mov_5_text"
                                      :lang="$attrs.lang.chapter5"
                                      :platform="$attrs.platform"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import BodySubFeaturesTemplate from '@/components/BodySubFeaturesTemplate'
import { computed, onMounted, reactive } from 'vue'
import { videoHandler } from '@/utils/features'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const chapterMaxHeight = reactive(computed(() => store.getters.getSubFeaturesChapterMaxHeight.chapterMaxHeight + 'px'))
const pageMaxHeight = reactive(computed(() => store.getters.getSubFeaturesChapterMaxHeight.pageMaxHeight + 'px'))

// Methods

// Lifecycles

onMounted(() => {
  videoHandler('video', 'video')
})

</script>

<style module="features">

.layout {
  height: v-bind(pageMaxHeight);
  background-color: #f9f9f9;
}

.video {
  width: 100%;
  max-width: 600px;
}

.check_point1 {
  position: absolute;
  right: 120px;
  top: 220px;
  width: 30px;
}

.check_point2 {
  position: absolute;
  left: 36%;
  top: 42%;
  width: 260px;
}

</style>

<style scoped>
.chapter {
  height: v-bind(chapterMaxHeight);
}
</style>
