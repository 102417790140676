<template>
  <footer id="footerMain"
          :class="$attrs.platform === 'mobile'
            ? footer.mobile
            : footer.desktop"
          class="full-width q-py-xl bg-color_white">
    <div :class="$attrs.platform === 'mobile'
          ? 'column reverse'
          : 'row'">
      <div :class="$attrs.platform === 'mobile'
           ? ''
           : 'q-px-xl'"
           class="col-6">
        <footer-vito :lang="$attrs.lang.vito"
                     :platform="$attrs.platform"/>
      </div>
      <div :class="$attrs.platform === 'mobile'
            ? 'q-mb-xl q-pb-sm'
            : 'q-px-xl q-pb-xl'"
           class="col-6">
        <footer-share :lang="$attrs.lang.share"
                      :platform="$attrs.platform"/>
      </div>
    </div>
    <footer-menu :lang="$attrs.lang.menu"
                 :platform="$attrs.platform"/>
  </footer>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import FooterVito from '@/components/FooterVito'
import FooterShare from '@/components/FooterShare'
import FooterMenu from '@/components/FooterMenu'

// Props

// Emits

// Variables

const store = useStore()
const pageMaxWidth = store.getters.getPageMaxWidth + 'px'
const pageMaxHeight = store.getters.getFooterMaxHeight + 'px'

// Responses

// Methods

// Lifecycles

</script>

<style module="footer">

.desktop {
  width: 100%;
  max-width: v-bind(pageMaxWidth);
  height: v-bind(pageMaxHeight);
}

.mobile {
  width: 100%;
  padding: 40px 0;
  max-width: v-bind(pageMaxWidth);
  height: 640px;
  background-color: #f7f7f7 !important;
}
</style>
