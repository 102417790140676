<template>
  <div v-if="banner" :class="topHeader.desktop">
    <a
      target="_blank"
      :href="banner.link"
      :style="{ backgroundColor: banner.bg_color }"
      :class="topHeader.wrapper"
    >
      <img :src="banner.image_url" />
    </a>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      banner: null
    }
  },

  created () {
    axios
      .get(`${process.env.VUE_APP_BANNER_URL}`, { timeout: 5000 })
      .then((response) => {
        const banners = response.data.banners

        const min = Math.ceil(0)
        const max = Math.floor(banners.length)
        const random = Math.floor(Math.random() * (max - min)) + min
        this.banner = banners[random]
      })
      .catch(() => {})
  }
}
</script>

<style module="topHeader">

.wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 84px;
  display: flex;
  justify-content: center;
}

@media (max-width: 1280px) {
  .desktop {
    display: none;
  }
}

</style>
