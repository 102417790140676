<template>
  <div id="bodyWhenIsUseful"
       :class="bodySubWhy.layout"
       class="relative-position">
    <p class="row justify-center text-title_large_bold">
      {{ $attrs.lang.title }}
    </p>
    <div class="column">
      <div class="row cases full-width no-wrap">
        <div id="bodySubWhenIsUsefulTemplate1"
             :class="bodySubWhy.template"
             class="col-6"
        >
          <body-sub-when-is-useful-template
            :imageURL="require('@/assets/images/img_whenIsUseful1.png')"
            :imageWidth="184"
            :lang="$attrs.lang.case1"
            :platform="$attrs.platform"
            :subTitle="$attrs.lang.subTitle"/>
        </div>
        <div id="data-section-1"
             class="col-6"
             data-section-1/>
      </div>
      <div class="row cases full-width no-wrap">
        <div id="data-section-2"
             class="col-6"
             data-section-2/>
        <div id="bodySubWhenIsUsefulTemplate2"
             :class="bodySubWhy.template"
             class="col-6"
        >
          <body-sub-when-is-useful-template
            :imageURL="require('@/assets/images/img_whenIsUseful2.png')"
            :imageWidth="192.3"
            :lang="$attrs.lang.case2"
            :platform="$attrs.platform"
            :subTitle="$attrs.lang.subTitle"/>
        </div>
      </div>
      <div class="row cases full-width no-wrap">
        <div id="bodySubWhenIsUsefulTemplate3"
             :class="bodySubWhy.template"
             class="col-6"
        >
          <body-sub-when-is-useful-template
            :imageURL="require('@/assets/images/img_whenIsUseful3.png')"
            :imageWidth="168.5"
            :lang="$attrs.lang.case3"
            :platform="$attrs.platform"
            :subTitle="$attrs.lang.subTitle"/>
        </div>
        <div id="data-section-3"
             class="col-6"
             data-section-3/>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports

import { onMounted } from 'vue'
import { useStore } from 'vuex'
import BodySubWhenIsUsefulTemplate from '@/components/BodySubWhenIsUsefulTemplate'
import { fixedRelease } from '@/utils/whenisUseful'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const pageMaxHeight = store.getters.getSubWhenIsUsefulMaxHeight + 'px'
const sectionMaxHeight = store.getters.getSubWhenIsUsefulSectionMaxHeight + 'px'

// Methods

// Lifecycles

onMounted(() => {
  fixedRelease(['#bodySubWhenIsUsefulTemplate1', '#bodySubWhenIsUsefulTemplate2', '#bodySubWhenIsUsefulTemplate3'])
})

</script>

<style module="bodySubWhy">
.layout {
  height: v-bind(pageMaxHeight);
}

.template {
  padding-top: 180px;
}

</style>

<style scoped>
.cases {
  height: v-bind(sectionMaxHeight);
}
</style>
