<template>
  <div id="footerShare"
       :class="$attrs.platform === 'mobile'
        ? 'q-pl-lg'
        : 'q-pb-lg'">
    <p :class="$attrs.platform === 'mobile'
        ? 'text-body_small_bold'
        : 'text-body_large_bold'"
       class="no-margin no-padding">
      {{ $attrs.lang.title }}
    </p>
    <div :class="$attrs.platform === 'mobile'
          ? 'q-gutter-md q-mt-xs'
          : 'q-gutter-lg q-mt-xs'">
      <q-btn v-for="(item, idx) in shares"
             :key="idx"
             :ripple="false"
             :tabindex="idx+16"
             class="no-padding"
             dense
             flat
             round
             @click="openURL(item.link)">
        <q-img :src="require(`@/assets/images/img_footer_${item.name}.png`)"
               :width="$attrs.platform === 'mobile'
                ? '38px'
                : '48px'"
               no-spinner/>
      </q-btn>
    </div>
    <div :class="$attrs.platform === 'mobile'
          ? 'q-mt-lg q-pb-xs'
          : 'q-mt-xl q-pt-md q-pb-sm'">
      <p :class="$attrs.platform === 'mobile'
          ? 'text-caption_large'
          : 'text-body_small'"
         class="text-color_lightgray3">
        {{ $attrs.lang.customer }}
      </p>
    </div>
    <q-btn :class="share.btn"
           :href="`mailto:${getInfoEmail}`"
           :ripple="false"
           flat
           rounded
           tabindex="20">
      <q-icon class="text-color_lightgray3 q-pl-xs"
              name="email"
              size="xs"/>
      <p :class="$attrs.platform === 'mobile'
          ? 'q-ma-xs'
          : 'q-ma-sm'"
         class="text-caption_large text-color_lightgray3">
        {{ $attrs.lang.btn }}
      </p>
    </q-btn>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { openURL } from 'quasar'

// Props

// Emits

// Variables

const store = useStore()
const shares = store.getters.getShares
const getInfoEmail = store.getters.getInfoEmail

// Responses

// Methods

// Lifecycles

</script>
<style module="share">
.btn {
  border: 1px solid #dddddd !important;
}
</style>
