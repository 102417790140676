import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'

gsap.registerPlugin(ScrollTrigger)

export const titlesMotion = () => {
  return gsap.to('.slogans', {
    scrollTrigger: {
      trigger: '#bodySubIntroduction',
      start: 'top 60%',
      end: '+=300px',
      scrub: true
    },
    stagger: 0.6,
    color: 'black'
  })
}
