<template>
  <div :class="$attrs.platform === 'mobile'
          ? security_pattern.mobile
          : security_pattern.desktop"
       class="bg-color_darkBlue"
  >
    <q-img height="300px"
           no-spinner
           src="@/assets/images/img_security_pattern.png"
           width="100%"/>
  </div>
</template>

<script setup>
// Imports
import { useStore } from 'vuex'
import { computed, reactive } from 'vue'

// Props

// Emits

// Variables
const store = useStore()
const pageMaxHeight = reactive(computed(() => store.getters.getSubSecurityMaxHeight + 'px'))

// Responses

// Watches

// Methods

// Lifecycles

</script>

<style module="security_pattern">
.mobile {
  position: absolute;
  width: 100vw;
  height: 1760px;
  top: 8690px;
  z-index: -1;
}

.desktop {
  position: absolute;
  width: 100vw;
  height: v-bind(pageMaxHeight);
  top: 9720px;
  z-index: -1;
}
</style>
