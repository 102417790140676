<template>
  <div v-if="$attrs.platform === 'mobile'"
       id="bodySubFeaturesMobile"
       :class="featuresMobile.layout">
    <div v-for="(video, idx) in features"
         :key="idx"
         class="column reverse feature q-mb-xl q-pb-xl">
      <div :id="`feature_mov_${video.id}_container_mobile`"
           class="col-8 row justify-center items-center q-px-md">
        <video :id="`feature_mov_${video.id}_mobile`"
               :class="featuresMobile.videoMobile"
               class="video no-margin no-padding"
               loop
               muted
               playsinline
               preload="auto">
          <source :src="require(`@/assets/video/${video.filename}.mp4`)"
                  type="video/mp4">
        </video>
      </div>
      <div :id="`feature_mov_${video.id}_text_container_mobile`"
           class="col-4 row justify-center q-px-md q-pb-xl relative-position">
        <body-sub-features-template :id="`feature_mov_${video.id}_text_mobile`"
                                    :lang="$attrs.lang[`chapter${video.id}`]"
                                    :platform="$attrs.platform"/>
      </div>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { onMounted } from 'vue'
import { videoHandler } from '@/utils/features'
import BodySubFeaturesTemplate from '@/components/BodySubFeaturesTemplate'

// Props

// Emits

// Variables

const store = useStore()
const features = store.getters.getSubFeaturesVideo

// Responses

// Methods

// Lifecycles

onMounted(() => {
  videoHandler('video', 'video')
})

</script>

<style module="featuresMobile">
.layout {
  height: 3050px;
  padding: 48px 0;
}

.videoMobile {
  overflow: hidden;
  border: 0;
  width: 100%;
  max-width: 400px;
}

</style>
