<template>
  <div id="bodySubSecurity"
       :class="$attrs.platform === 'mobile'
        ? security.mobile
        : security.desktop"
       class="column items-center relative-position">
    <div class="relative-position q-py-md full-width">
      <p :class="$attrs.platform === 'mobile'
          ? 'text-title_small_bold '
          : 'text-title_large_bold'"
         class="text-color_white q-pb-md q-px-xl text-center"
         v-html="$attrs.platform === 'mobile'
                  ? $attrs.lang.titleMobile
                  : $attrs.lang.title "/>
      <p class="text-color_white_80 text-center q-mb-xl"
         :class="$attrs.platform === 'mobile'
          ? 'text-body_small'
          : 'text-body_normal'"
         v-html="$attrs.lang.description"/>
      <div :class="$attrs.platform === 'mobile'
            ? 'column items-center'
            : 'row justify-center'"
           class="q-mx-auto text-center q-mb-md">
        <q-img no-spinner
               src="@/assets/images/img_security_protect.png"
               width="22px"
               height="22px"
               :class="$attrs.platform === 'mobile'
               ? 'q-mb-md' : ''"/>
        <span class="text-color_white text-body_small_bold text-center q-mb-xl"
              :class="$attrs.platform === 'mobile' ? '' : 'q-pl-sm'"
              v-html="$attrs.platform === 'mobile' ? $attrs.lang.subTitleMobile : $attrs.lang.subTitle "/>
      </div>
      <div class="q-mb-xl q-pb-md" :class="$attrs.platform === 'mobile' ? 'column items-center justify-center' : 'row full-width justify-center'">
        <div v-for="model in $attrs.lang.models"
             :key="model.name"
             :class="$attrs.platform === 'mobile' ? 'q-mb-md q-mx-sm' : 'col-3'">
          <body-sub-security-template :lang="model"
                                      :platform="$attrs.platform"/>
        </div>
      </div>
      <q-btn
          :ripple="false"
          flat
          color="black"
          class="row q-mx-auto bg-color_vitoYellow"
          :class="$attrs.platform === 'mobile' ? 'text-body_small_bold' : 'text-body_normal_bold'"
          :style="{ padding: '22px 40px', lineHeight: '1.4', borderRadius: '8px'}"
          @click="openURL(`${devURL}/?utm_source=vito_homepage&utm_medium=무료_click&utm_campaign=family_site_하단_developers`)"
          @mousedown="logEvent('action_section4_button_devapi')"
          tabindex="27"
          v-html="$attrs.platform === 'mobile' ? $attrs.lang.btnMobile : $attrs.lang.btn"
      >
      </q-btn>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { openURL } from 'quasar'
import { computed, reactive } from 'vue'
import { logEvent } from '../utils/amplitude'
import BodySubSecurityTemplate from '@/components/BodySubSecurityTemplate'

// Props

// Emits

// Variables

const store = useStore()
const devURL = store.getters.getDevURL

// Responses

const pageMaxHeight = reactive(computed(() => store.getters.getSubSecurityMaxHeight + 'px'))

// Methods

// Lifecycles

</script>

<style module="security">

.desktop {
  height: v-bind(pageMaxHeight);
  width: 100%;
  padding: 100px 20px;
}

.mobile {
  padding: 30px 12px;
}

</style>
