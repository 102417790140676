import useQuasar from 'quasar/src/composables/use-quasar.js';

export const checkPlatform = () => {
  const $q = useQuasar()
  // Screen width
  if ($q.screen.width < 1185) return 'mobile'
  // ipad
  if ($q.platform.is.linux && $q.platform.has.touch && $q.platform.is.desktop && $q.platform.is.mac) return 'mobile'
  // galaxy tab
  if ($q.platform.is.linux && $q.platform.has.touch) return 'mobile'
  // etc
  return $q.platform.is.mobile || $q.platform.is.android
    ? 'mobile'
    : 'desktop'
}
