<template>
  <div id="bodySubMain"
       :class="bodySubMain.layout"
       class="column full-width">
    <body-sub-introduction :lang="$attrs.lang.introduction"
                           :platform="$attrs.platform"/>
    <body-sub-when-is-useful v-if="$attrs.platform !== 'mobile'"
                             :lang="$attrs.lang.whenIsUseful"
                             :platform="$attrs.platform"/>
    <body-sub-when-is-useful-mobile v-else
                                    :lang="$attrs.lang.whenIsUseful"
                                    :platform="$attrs.platform"/>
    <body-sub-review :lang="$attrs.lang.review"
                     :platform="$attrs.platform"/>
    <body-sub-review-mobile :lang="$attrs.lang.review"
                            :platform="$attrs.platform"/>
    <body-sub-divider :lang="$attrs.lang.divider"
                      :platform="$attrs.platform"/>
    <body-sub-features :lang="$attrs.lang.features"
                       :platform="$attrs.platform"/>
    <body-sub-features-mobile :lang="$attrs.lang.features"
                              :platform="$attrs.platform"/>
    <body-sub-security :lang="$attrs.lang.security"
                       :platform="$attrs.platform"/>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { computed, onMounted, reactive } from 'vue'
import { floatingBanner } from '@/utils/floatingBanner'
import BodySubReview from '@/components/BodySubReview'
import BodySubDivider from '@/components/BodySubDivider'
import BodySubFeatures from '@/components/BodySubFeatures'
import BodySubSecurity from '@/components/BodySubSecurity'
import BodySubIntroduction from '@/components/BodySubIntroduction'
import BodySubReviewMobile from '@/components/BodySubReviewMobile'
import BodySubWhenIsUseful from '@/components/BodySubWhenIsUseful'
import BodySubWhenIsUsefulMobile from '@/components/BodySubWhenIsUsefulMobile'
import BodySubFeaturesMobile from '@/components/BodySubFeaturesMobile'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const pageMaxWidth = reactive(computed(() => store.getters.getPageMaxWidth + 'px'))

// Methods

// Lifecycles

onMounted(() => floatingBanner())

</script>

<style module="bodySubMain">
.layout {
  max-width: v-bind(pageMaxWidth);
}

</style>
