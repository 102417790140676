import store from '../store'
import { defaultHeight, decreaseHeight, increaseHeight } from '@/utils/heightHandler'
import { gsap } from '@/assets/modules/gsap.min'

let swipeCenter = store.getters.getEqProps.center

const stagger = (i = 'edges') => {
  return {
    from: i,
    each: 0.03
  }
}

export const eqDefault = (el, centerEl) => {
  return gsap.to(el, {
    delay: 0.8,
    duration: 2,
    width: (n) => n === centerEl ? 224 : n === centerEl - 3 ? 30 : n === centerEl - 2 || n === centerEl - 1 || n === centerEl + 1 || n === centerEl + 2 ? 224 : n === centerEl + 3 ? 30 : 10,
    height: (n) => n === centerEl ? 480 : n === centerEl - 3 ? 30 : n === centerEl - 2 || n === centerEl - 1 || n === centerEl + 1 || n === centerEl + 2 ? gsap.utils.random(270, 380) : n === centerEl + 3 ? 30 : 10,
    stagger: stagger(),
    ease: 'elastic.out(.5, .3)',
    onUpdate: () => {
      const centerElWidth = gsap.getProperty(el[centerEl], 'width')
      if (centerElWidth > 50) defaultHeight(el, centerEl)
    },
    onComplete: () => {
      store.commit('setMouseEnableOnUserCases', true)
    }
  })
}

export const eqIncrease = (i = 0, centerEl, el, extra) => {
  extra.forEach((el, idx) => {
    return gsap.to(el, {
      duration: 0.8,
      height: () => idx === centerEl - 2 || idx === centerEl - 3 ? gsap.utils.random(35, 70) : gsap.utils.random(10, 20),
      ease: 'elastic.out(.5, .3)'
    })
  })
  return gsap.to(el, {
    duration: 0.8,
    height: (no) => no !== i ? gsap.utils.random(270, 380) : 480,
    stagger: stagger(i),
    ease: 'elastic.out(.5, .3)'
  })
}

export const eqSwiper = (direction, centerEl, responseEl, extraEl) => {
  if (direction === 'left') {
    if (swipeCenter === centerEl + 2) {
      return gsap.to('.eqContainer', {
        x: '-=0px'
      })
    }
    return gsap.to('.eqContainer', {
      x: '-=246px',
      ease: 'back',
      onStart: () => {
        swipeCenter += 1
        store.commit('setMouseEnableOnUserCases', false)
      },
      onComplete: () => {
        decreaseHeight(responseEl)
        increaseHeight(swipeCenter, centerEl, responseEl, extraEl)
        store.commit('setMouseEnableOnUserCases', true)
      }
    })
  }
  if (direction === 'right') {
    if (swipeCenter === centerEl - 2) {
      return gsap.to('.eqContainer', {
        x: '-=0px'
      })
    }
    return gsap.to('.eqContainer', {
      x: '+=246px',
      ease: 'back',
      onStart: () => {
        swipeCenter -= 1
        store.commit('setMouseEnableOnUserCases', false)
      },
      onComplete: () => {
        decreaseHeight(responseEl)
        increaseHeight(swipeCenter, centerEl, responseEl, extraEl)
        store.commit('setMouseEnableOnUserCases', true)
      }
    })
  }
}
