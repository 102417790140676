export default {
  state: {
    footer: {
      pageMaxHeight: 480,
      shares: [
        {
          name: 'kakaotalk',
          link: 'https://pf.kakao.com/_nvIAxb'
        },
        {
          name: 'instagram',
          link: 'https://www.instagram.com/vito__ai/'
        },
        {
          name: 'facebook',
          link: 'https://www.facebook.com/VoiceInTextOut'
        },
        {
          name: 'naverBlog',
          link: 'https://blog.naver.com/vito_ai'
        }
      ]
    }
  },
  getters: {
    getShares: (state) => state.footer.shares,
    getFooterMaxHeight: (state) => state.footer.pageMaxHeight

  }
}
