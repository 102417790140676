export default {
  state: {
    phone_video: '1'
  },
  getters: {
    getCurrentPhoneVideo: (state) => state.phone_video
  },
  mutations: {
    setPhoneVideo (state, payload) {
      state.phone_video = payload
    }
  }
}
