import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'

gsap.registerPlugin(ScrollTrigger)

export const topHeader = () => {
  return gsap.to('#topHeaderFloating, #topHeader_bg', {
    scrollTrigger: {
      trigger: '#appDownload',
      start: 'top',
      end: '+=100px',
      scrub: true
    },
    visibility: 'visible',
    top: 0
  })
}
