<template>
  <q-toolbar id="logo_slogan"
             :class="topHeader.layout"
             class=" no-margin no-padding">
    <div :class="$attrs.platform === 'mobile'
         ? 'col-4'
         : 'col-6'"
         class="row items-center">
      <q-btn :ripple="false"
             class="q-ml-lg"
             dense
             flat
             tabindex="1"
             href="/"
             @mousedown="logEvent('action_head_button_logo')"
             >
          <img :class="topHeader.logo"
             src="@/assets/images/img_top_logo.png"/>
      </q-btn>
      <p v-if="$attrs.platform !== 'mobile'"
         :class="topHeader.slogan"
         class="text-caption_large q-pl-sm no-margin">
        {{ $attrs.lang.slogan }}
      </p>
    </div>
    <div v-if="$attrs.platform === 'mobile'"
         class="col-8 row justify-end q-pr-lg">
      <q-btn dense
             flat
             class="no-padding"
             :ripple="false"
             @click="openURL(`${devURL}/?utm_source=vito_homepage&utm_medium=무료_click&utm_campaign=family_site_상단_api`)"
             @mousedown="logEvent('action_head_button_devapi')"
      >
        <p class="no-margin text-caption_large q-pr-md">{{ $attrs.lang.nav[1].name  }}</p>
      </q-btn>
      <q-btn
          :ripple="false"
          class="download bg-color_black text-color_white"
          dense
          flat
          @click="appDownload('android')"
          @mousedown="logEvent('action_main_button_google')"
          >
        <p :class="$attrs.platform === 'mobile'
              ? 'text-caption_normal'
              : 'text-caption_large'"
           class="no-margin">
          {{ $attrs.lang.appDownload }}
        </p>
      </q-btn>
    </div>
    <div v-else class="col-6 row justify-end q-pr-xl">
      <q-btn dense
             flat
             class="no-padding"
             :ripple="false"
             @click="openURL(`${devURL}/?utm_source=vito_homepage&utm_medium=무료_click&utm_campaign=family_site_상단_api`)"
             @mousedown="logEvent('action_head_button_devapi')"
      >
        <p class="no-margin text-body_small">{{ $attrs.lang.nav[1].name }}</p>
      </q-btn>
      <q-separator vertical inset class="q-mx-lg"/>
      <q-btn dense
             flat
             class="no-padding"
             :ripple="false"
             @click="openURL(webURL)"
            @mousedown="logEvent('action_head_button_myvito')"
             >
        <p class="no-margin text-body_small"
        >{{ $attrs.lang.nav[0].name }}</p>
      </q-btn>
    </div>
  </q-toolbar>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { openURL } from 'quasar'
import { appDownload } from '@/utils/appDownlaod'
import { logEvent } from '../utils/amplitude'

// Props

// Emits

// Variables

const store = useStore()
const pageMaxWidth = store.getters.getPageMaxWidth + 'px'
const headerMaxHeight = store.getters.getTopMaxHeight + 'px'
const webURL = store.getters.getWebURL
const devURL = store.getters.getDevURL

// Responses

// Methods

// Lifecycles

</script>

<style module="topHeader">
.layout {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  height: v-bind(headerMaxHeight);
  min-width: 320px;
  max-width: v-bind(pageMaxWidth);
  max-height: v-bind(headerMaxHeight);
  z-index: 10;
}

.layout .logo {
  max-width: 60px;
}

.slogan {
  margin-top: 1px;
}

</style>
<style scoped>
.download {
  border: 1px solid rgba(0, 0, 0, .05);
  padding: 4px 12px;
  border-radius: 24px;
  opacity: 1;
  z-index: 12;
}
</style>
