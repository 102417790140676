<template>
  <div id="bodySubFeaturesTemplate"
       :class="$attrs.platform === 'mobile'
        ? 'text-center'
        : 'q-px-lg'"
       class="column full-width full-height items-center justify-center">
    <div>
      <p :class="$attrs.platform === 'mobile'
          ? 'text-body_large_bold'
          : 'text-title_normal_bold'"
         class="text-color_black"
         v-html="$attrs.lang.title"/>
      <p :class="$attrs.platform === 'mobile'
          ? 'text-body_small'
          : 'text-body_normal'"
         v-html="$attrs.lang.description"/>
      <q-btn v-if="$attrs.platform !== 'mobile'"
             class="row items-center justify-center no-padding no-margin text-color_vitoBlue text-bold"
             :ripple="false"
             dense
             @click="openURL(webURL)"
             @mousedown="logEvent('action_section3_button_myvito')"
             flat>
        <p :class="$attrs.platform === 'mobile'
          ? 'text-body_small'
          : 'text-body_normal'"
           class="no-margin"
           v-html="$attrs.lang.button"/>
      </q-btn>
    </div>
  </div>
</template>

<script setup>
// Imports
import { openURL } from 'quasar'
import { useStore } from 'vuex'
import { logEvent } from '../utils/amplitude'

// Props

// Emits

// Variables

const store = useStore()
const webURL = store.getters.getWebURL

// Responses

// Methods

// Lifecycles

</script>
