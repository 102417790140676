<template>
  <div v-if="$attrs.platform === 'mobile'"
       id="reviewers"
       :class="reviewMobile.layout"
       class="relative-position"
       data-section-4>
    <div v-for="(profile, idx) in $attrs.lang.profiles.slice(0,3)"
         :key="idx"
         class="column items-end q-mb-lg">
      <q-btn :class="reviewMobile.profileWrapper"
             :ripple="false" class="column q-mr-md"
             flat
      >
        <review-popup
          :isShow="true"
          :message="profile.message"
          :platform="$attrs.platform"
          :rating="profile.rating"
          class="review_popup"
        />
        <q-avatar class="q-mb-xs avatar"
                  size="60px">
          <q-img :class="reviewMobile.profile"
                 :src="require(`@/assets/images/${profile.img}`)"
                 height="100%"
                 no-spinner
                 width="100%"/>
        </q-avatar>
        <p :class="reviewMobile.name"
           class="text-caption_large no-padding no-margin">{{ profile.name }}</p>
      </q-btn>
    </div>
    <div class="column items-end q-mt-md">
      <p class="no-margin text-body_small">
        {{ $attrs.lang.title }}
      </p>
      <q-btn :ripple="false"
             class="text-color_vitoBlue"
             dense
             flat
             @click="appDownload('android')">
        <p class="text-body_small_bold" v-html="$attrs.lang.subtitle"/>
      </q-btn>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { useQuasar } from 'quasar'
import ReviewPopup from '@/components/BodySubReviewPopup'
import { appDownload } from '@/utils/appDownlaod'

// Props

// Emits

// Variables

const $q = useQuasar()

// Responses

const store = useStore()
const popupWidth = ($q.screen.width - 124) + 'px'
const pageMaxHeight = store.getters.getSubReviewMobileMaxHeight + 'px'

// Methods

// Lifecycles

</script>

<style module="reviewMobile">
.layout {
  padding: 24px 24px;
  height: v-bind(pageMaxHeight);
}

.profileWrapper {
  width: 100%;
  max-width: 80px;
  margin-bottom: 60px;
  z-index: 8;
}

.profile {
  max-width: 66px;
  max-height: 66px;
}

.name {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

<style scoped>
.review_popup {
  position: absolute;
  width: v-bind(popupWidth);
  max-width: 380px;
  right: 60px;
}

.avatar {
  width: 66px;
  height: 66px;
  background-color: #eeeeee;
}

</style>
