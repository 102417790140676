<template>
  <div :id="`bodyPhoneMobile${$attrs.video}`"
       class="row justify-center items-center phoneMobile relative-position">
    <img :class="bodyPhoneMobile.overlap"
         src="@/assets/images/img_phone_device_layered.png"/>
    <img :class="bodyPhoneMobile.device"
         class="row justify-center items-center"
         src="@/assets/images/img_phone_device.png"/>
    <div id="video_loading"
         :class="bodyPhoneMobile.loading"/>
    <video :class="bodyPhoneMobile.vid_phone_case_mobile"
           autoplay
           class="vid_phone_case_mobile"
           loop
           muted
           playsinline>
      <source :src="require(`@/assets/video/vid_phone_case${$attrs.video}.mp4`)"
              type="video/mp4"/>
    </video>
  </div>
</template>

<script setup>
// Imports

import { computed, onMounted, reactive, watch, ref } from 'vue'
import { useStore } from 'vuex'
import { videoHandler } from '@/utils/features'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const videoEl = ref(null)
const pageWidth = reactive(computed(() => store.getters.getPageWidth))

// Methods

// Watches

watch(pageWidth, () => {
  checkVideo(videoEl.value)
})

const checkVideo = (el) => {
  if (pageWidth.value > 476) {
    el.forEach(e => {
      e.style.padding = '68px 74px 64px 74px'
    })
    return ''
  }
  el.forEach(e => {
    e.style.padding = '14px 16.6% 0 16.6%'
  })
}

// Lifecycles

onMounted(() => {
  videoEl.value = document.querySelectorAll('.vid_phone_case_mobile')
  checkVideo(videoEl.value)
  videoHandler('phoneMobile', 'vid_phone_case_mobile')
})

</script>

<style module="bodyPhoneMobile">
.overlap {
  position: absolute;
  width: 100vw;
  max-width: 474px;
  mix-blend-mode: darken;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.device {
  position: absolute;
  width: 100vw;
  max-width: 474px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.loading {
  position: absolute;
  width: 330px;
  height: 620px;
  background-color: white;
  opacity: 0;
  border-radius: 24px;
  z-index: 1;
}

.vid_phone_case_mobile {
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 474px;
  z-index: 0;
}

</style>
