<template>
  <div id="appDownload"
       :class="$attrs.platform === 'mobile'
       ? bodyTopMainAppDownload.mobile
       : bodyTopMainAppDownload.desktop"
       class="row justify-center">
    <!-- 아이폰 -->
    <!--    <q-btn :class="$attrs.platform === 'mobile'-->
    <!--            ? bodyTopMainAppDownload.mobileDownload-->
    <!--            : bodyTopMainAppDownload.desktopDownload"-->
    <!--           class="row items-center bg-color_white"-->
    <!--           flat-->
    <!--           rounded-->
    <!--           tabindex="5"-->
    <!--           @click="openURL(googleAppDownload)">-->
    <!--      <q-img :height="$attrs.platform === 'mobile'-->
    <!--              ? '20px'-->
    <!--              : '22px'"-->
    <!--             :width="$attrs.platform === 'mobile'-->
    <!--             ? '19px'-->
    <!--             : '19px'"-->
    <!--             no-spinner-->
    <!--             src="@/assets/images/img_appStore.png"/>-->
    <!--      <p :class="$attrs.platform === 'mobile'-->
    <!--          ? 'text-body_small_bold'-->
    <!--          : 'text-body_small_bold no-padding'"-->
    <!--         class="text-color_black q-ml-sm q-mb-xs">-->
    <!--        {{ $attrs.lang.appStore }}-->
    <!--      </p>-->
    <!--    </q-btn>-->
    <!-- 안드로이드 -->
    <q-btn :class="$attrs.platform === 'mobile'
            ? bodyTopMainAppDownload.mobileDownload
            : bodyTopMainAppDownload.desktopDownload"
           class="row items-center bg-color_black"
           flat
           rounded
           tabindex="5"
           @click="appDownload('android')"
           @mousedown="logEvent('action_main_button_google')"
           >
      <q-img :height="$attrs.platform === 'mobile'
              ? '20px'
              : '22px'"
             :width="$attrs.platform === 'mobile'
             ? '16px'
             : '16px'"
             no-spinner
             src="@/assets/images/img_googlePlay.png"/>
      <p :class="$attrs.platform === 'mobile'
          ? 'text-body_small_bold no-padding'
          : 'text-body_normal_bold no-padding'"
         class="text-color_white q-ml-sm q-mb-xs q-tab--no-caps"
         style="letter-spacing: -0.3px; margin-top: 3px">
        {{ $attrs.lang.googlePlay }}
      </p>
    </q-btn>
    <!-- iPhone 설명 -->
    <q-btn :ripple="false"
           class="row q-mt-sm"
           dense
           flat
           tabindex="6">
      <img :class="$attrs.platform === 'mobile'
            ? bodyTopMainAppDownload.iphoneMobile
            : bodyTopMainAppDownload.iphoneDesktop"
           src="@/assets/images/img_forIphone.png"/>
      <p class="text-body_small q-pl-sm no-margin">
        {{ $attrs.lang.iPhone }}
      </p>
      <iphone-info-popup :iPhonePopup="$attrs.lang.iPhonePopup"
                         :platform="$attrs.platform"/>
    </q-btn>
  </div>
</template>

<script setup>
// Imports
import { defineAsyncComponent } from 'vue'
import { appDownload } from '@/utils/appDownlaod'

// Props

// Emits

// Variables

const iphoneInfoPopup = defineAsyncComponent({
  loader: () => import('@/components/BodyTopMainAppDownloadIphonePopup')
})

// Responses

// Methods

// Lifecycles

</script>

<style module="bodyTopMainAppDownload">
.desktop {
  width: 190px;
  height: 70px;
  margin-top: 10px;
  margin-right: 24px;
}

.mobile {
  width: 190px;
  height: 100px;
  margin-top: 10px !important;
}

.desktopDownload {
  height: 58px !important;
  padding: 12px 26px !important;
  border: 2px solid #222 !important;
  border-radius: 34px !important;
}

.mobileDownload {
  height: 56px !important;
  padding: 12px 28px !important;
  border-radius: 34px !important;
  margin-top: 60px;
}

.iphoneDesktop {
  width: 16px;
  height: 16px;
}

.iphoneMobile {
  width: 16px;
  height: 16px;
  margin-top: 1px;
}

</style>
