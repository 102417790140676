<template>
  <div id="bodyTopMainSlogan"
       :class="$attrs.platform === 'mobile'
        ? bodyTopMainSlogan.mobile
        : bodyTopMainSlogan.desktop">
    <div :class="$attrs.platform === 'mobile'
          ? 'row justify-center'
          : 'row justify-start'"
         class="relative-position">
      <p :class="$attrs.platform === 'mobile'
          ? width < 1185 && width > 800
              ? 'text-title_normal_bold q-pr-xs text-center'
              : 'text-title_small_bold text-center'
          : 'text-title_large_bold q-pr-xs'"
         class="no-margin">
        {{ $attrs.lang.title }}
      </p>
      <div :class="$attrs.platform === 'mobile'
            ? width < 1185 && width > 800
                ? bodyTopMainSlogan.mobileWrapper2
                : bodyTopMainSlogan.mobileWrapper
            : bodyTopMainSlogan.desktopWrapper"
           class="row justify-center">
        <p v-for="(title, idx) in $attrs.lang.rollingTitle"
           :key="idx"
           :class="$attrs.platform === 'mobile'
            ? width < 1185 && width > 800
              ? 'text-title_normal_bold absolute'
              : 'text-title_small_bold absolute'
            : 'text-title_large_bold absolute'"
           class="rollingTitle no-margin no-padding text-color_paleBlue1">
          {{ title }}
        </p>
      </div>
      <p :class="$attrs.platform === 'mobile'
        ? width < 1185 && width > 800
          ? 'text-title_normal_bold'
          : 'text-title_small_bold'
        : 'text-title_large_bold'">
        {{ $attrs.lang.rollingTitlePeriod }}
      </p>
    </div>
    <p :class="$attrs.platform === 'mobile'
          ? 'text-body_small justify-center text-center'
          : 'text-body_normal'"
       class="row"
       v-html="$attrs.lang.description"/>
  </div>
</template>

<script setup>
// Imports
import { sloganMotion } from '@/utils/sloganMotion'
import { computed, onMounted, reactive } from 'vue'
import { useQuasar } from 'quasar'

// Props

// Emits

// Variables

const $q = useQuasar()

// Responses

const width = reactive(computed(() => $q.screen.width))

// Methods

// Lifecycles

onMounted(() => {
  sloganMotion()
})

</script>

<style module="bodyTopMainSlogan">
.desktop {
  width: 100%;
  min-width: 400px;
  padding: 0 0 0 24px;
  justify-content: start;
}

.mobile {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.desktopWrapper {
  width: 164px;
  margin-right: 4px;
}

.mobileWrapper {
  width: 110px;
  height: 40px;

}

.mobileWrapper2 {
  width: 126px;
  height: 40px;
  margin-right: 4px;
}
</style>

<style scoped>
.rollingTitle {
  color: #94a0be;
}
</style>
