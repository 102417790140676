<template>
  <q-dialog id="bodyTopMainUserCasePopup"
            v-model="isUserCasePopup"
  >
    <user-case-popups :headers="$attrs.lang.headers"
                      :platform="$attrs.platform"
                      :selectedJobIndex="selectedJobIndex"
                      @closePopup="closePopup"/>
  </q-dialog>
  <div id="bodyUserCases"
       v-touch-swipe.horizontal="checkUserPlatform === 'mobile' && enableMouseEvent
        ? onSwiperHandler
        : ''"
       v-touch-swipe.mouse.horizontal="checkUserPlatform === 'mobile' && enableMouseEvent
       ? onSwiperHandler
       : ''"
       :class="userCaseStyle.eqWrapper"
       class="row absolute-center justify-center items-center">
    <div
      v-for="(eq, i) in eq.counts"
      :key="i"
      :class="userCaseStyle.eqContainer"
      class="eqContainer relative-position"
      @click="enableMouseEvent && i > selection - 3 && i < selection + 3
              ? openUserCasePopup(i)
              : ''"
      @mouseenter="userPlatform !== 'mobile' && enableMouseEvent
                  && i > selection - 3
                  && i < selection + 3
            ? (decreaseHeight(responseEl),
                increaseHeight(i, selection, responseEl, extraEl),
                currentEl = i
              )
            : ''"
    >
      <div v-if="i > selection -3 && i < selection + 3"
           :class="userCaseStyle.callingWrapper">
        <q-img v-if="isShowCallingIcon"
               :class="userCaseStyle.calling"
               no-spinner
               src="@/assets/images/img_userCase_calling.svg"/>
      </div>
      <p v-if="i > selection -3 && i < selection + 3"
         :class="userCaseStyle.title"
         class="text-title_small_bold">{{ $attrs.lang.jobs[i - (selection - 2)].name }}</p>
      <strong v-if="i > selection -3 && i < selection + 3"
              :class="userCaseStyle.description"
              class="text-body_small text-weight-regular text-center"
              v-html="$attrs.lang.jobs[i - (selection - 2)].description"/>
      <div :class="userCaseStyle.eq"
           class="eq column justify-end items-center text-center hide-scrollbar overflow-hidden">
        <q-img :class="userCaseStyle.img"
               :src="i > selection -3 && i < selection + 3
                  ? require(`@/assets/images/img_userCase${fromCenterNum(i)}.png`)
                  : ''"
               no-spinner
               position="center center"/>
      </div>
    </div>

  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted, reactive, ref, defineAsyncComponent } from 'vue'
import { eqDefault, eqSwiper } from '@/utils/eqMotion'
import { increaseHeight, decreaseHeight } from '@/utils/heightHandler'
import { checkPlatform } from '@/utils/checkPlatform'

// Props

// Emits

// Variables

const store = useStore()
const userCasePopups = defineAsyncComponent({
  loader: () => import('@/components/BodyTopMainUserCasePopup')
})

// Responses

const wave = ref(null)
const isShowCallingIcon = ref(true)
const extraEl = ref([])
const selectedJobIndex = ref(null)
const userPlatform = ref(null)
const isUserCasePopup = ref(false)
const checkUserPlatform = reactive(computed(() => store.getters.getUserPlatform))
const eq = reactive(computed(() => store.getters.getEqProps))
const selection = ref(computed(() => store.getters.getEqProps.center))
const responseEl = reactive(computed(() => [...wave.value].slice(selection.value - 2, selection.value + 3)))
const currentEl = ref(selection.value)
const leftEl = reactive(computed(() => [...wave.value].slice(selection.value - 5, selection.value - 2)))
const rightEl = reactive(computed(() => [...wave.value].slice(selection.value + 3, selection.value + 6)))
const enableMouseEvent = reactive(computed(() => store.getters.getMouseStatus))

// Methods
const fromCenterNum = (i) => Math.abs(i - (selection.value - 3))
const closePopup = () => {
  isUserCasePopup.value = false
  isShowCallingIcon.value = true
  store.commit('setIsOpenUserCasePopup', false)
}

const openUserCasePopup = (i) => {
  if (!enableMouseEvent.value) return ''
  if (wave.value[i].getBoundingClientRect().height < 480) {
    return ''
  }
  isShowCallingIcon.value = false
  selectedJobIndex.value = fromCenterNum(i)
  store.commit('setMouseEnableOnUserCases')
  isUserCasePopup.value = true
}

const onSwiperHandler = (v) => {
  eqSwiper(v.direction, selection.value, responseEl.value, extraEl.value)
}

// Lifecycles

onMounted(() => {
  wave.value = document.querySelectorAll('.eq')
  extraEl.value = [...leftEl.value, ...rightEl.value]
  eqDefault(wave.value, selection.value)
  userPlatform.value = checkPlatform()
})

onUnmounted(() => {
  wave.value = null
  extraEl.value = null
  userPlatform.value = null
})

</script>
<style module="userCaseStyle">

.eqWrapper {
  display: flex;
  flex-wrap: nowrap !important;
  overflow: hidden;
  white-space: nowrap;
  margin-top: -10px;
}

.eqWrapper .eqContainer {
  position: relative;
}

.eqWrapper .eqContainer .eq {
  width: v-bind(eq ['width'] + 'px');
  height: v-bind(eq ['height'] + 'px');
  border-radius: 300px;
  padding: 0;
}

.jobIcon {
  display: none;
  position: absolute;
  width: 40px;
  left: 50%;
  top: 15%;
  transform: translate(-50%, -50%);
  transition: opacity .2s ease-in-out;
}

.callingWrapper {
  display: none;
  position: absolute;
  width: 160px;
  height: 160px;
  left: 50%;
  padding: 0;
  transform: translate(-50%, -50%);
  bottom: -60px;
  z-index: 1;
  transition: opacity .2s ease-in-out;
}

.calling {
  width: 100%;
  height: 100%;
}

.eqWrapper .eqContainer:nth-child(odd) .title {
  display: none;
  position: absolute;
  left: 50%;
  top: 16%;
  color: #ffffff;
  transform: translate(-50%, -50%);
  transition: opacity .2s ease-in-out;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.eqWrapper .eqContainer:nth-child(even) .title {
  display: none;
  position: absolute;
  left: 50%;
  top: 16%;
  color: #ffffff;
  transform: translate(-50%, -50%);
  transition: opacity .2s ease-in-out;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.eqWrapper .eqContainer:nth-child(odd) .description {
  display: none;
  position: absolute;
  left: 50%;
  top: 27%;
  color: #ffffff;
  transform: translate(-50%, -50%);
  transition: opacity .2s ease-in-out;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.eqWrapper .eqContainer:nth-child(even) .description {
  display: none;
  position: absolute;
  left: 50%;
  top: 27%;
  color: #444444;
  transform: translate(-50%, -50%);
  transition: opacity .2s ease-in-out;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.3);
}

.eqWrapper .eqContainer:nth-child(odd) {
  background-color: #2E67FE;
  border-radius: 300px;
  margin: 0 12px;
}

.eqWrapper .eqContainer:nth-child(even) {
  background-color: #FFD500;
  border-radius: 300px;
  margin: 0 12px;
}

.eqWrapper .eqContainer .eq .img {
  width: 150%;
  margin-left: -50px;
  transition: all .2s ease-in-out;
}

</style>
