import { gsap } from '@/assets/modules/gsap.min'

export const sloganMotion = () => {
  const el = gsap.utils.toArray('.rollingTitle')
  const tl = gsap.timeline({
    repeat: -1
  })
  tl.from(el[0], {
    autoAlpha: 0,
    duration: 0.5
  })
  tl.to(el[0], {
    autoAlpha: 0,
    duration: 0.1,
    delay: 4
  })
  tl.from(el[1], {
    autoAlpha: 0,
    duration: 0.5
  })
  tl.to(el[1], {
    autoAlpha: 0,
    duration: 0.1,
    delay: 4
  })
  tl.from(el[2], {
    autoAlpha: 0,
    duration: 0.5
  })
  tl.to(el[2], {
    autoAlpha: 0,
    duration: 0.1,
    delay: 4
  })
}
