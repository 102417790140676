import store from '../store'
import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'
import useQuasar from 'quasar/src/composables/use-quasar.js';

gsap.registerPlugin(ScrollTrigger)

// Variables

// EventHandler

// Methods

const videoPromise = (video) => {
  const vidPromise = video.play()
  if (vidPromise !== undefined) {
    vidPromise.then(_ => {
      // Reloaded & play video
      video.play()
      return ''
    }).catch(() => {
      // video.load()
      return ''
    })
  }
}

const videoUpdate = (self) => {
  if (self.isActive && self.direction === 1 && self.progress > 0.1) {
    return gsap.to('#video_loading', {
      opacity: 1
    })
  }
  if (self.isActive && self.direction === -1 && self.progress < 0.95) {
    return gsap.to('#video_loading', {
      opacity: 1
    })
  }
}

export const fixedRelease = (el) => {
  const $q = useQuasar()
  el.forEach(e => {
    return gsap.to(e, {
      scrollTrigger: {
        trigger: e,
        start: () => $q.screen.height >= 968 ? 'top 15%' : 'top -10px',
        end: '+=200px',
        pin: true,
        anticipatePin: 1,
        scrub: true
      }
    })
  })
}

export const movePhone = () => {
  const $q = useQuasar()
  const tl = gsap.timeline({
    paused: true,
    duration: 3
  })

  ScrollTrigger.saveStyles('#bodyPhone')
  ScrollTrigger.matchMedia({
    '(min-width: 1188px)': function () {
      const video = document.querySelector('#vid_phone_case')
      const el = document.querySelector('#bodyPhone')
      el.style.opacity = 1
      let width = ''
      window.addEventListener('resize', function () {
        width = window.innerWidth / 2 + 100
      })

      // 처음 영상 시작
      tl.from('#bodyPhone', {
        position: 'absolute',
        top: 1600,
        left: width,
        opacity: 1,
        zIndex: 5
      })
        .to('#bodyPhone', {
          scrollTrigger: {
            trigger: '#data-section-1',
            start: () => $q.screen.height >= 968 ? 'top 15%' : 'top -10px',
            end: '+=200px',
            pin: '#bodyPhone',
            scrub: true,
            onToggle: (self) => {
              if (self.isActive) {
                // 스크롤업때만 리로드
                if (self.direction === -1) video.load()
                store.commit('setPhoneVideo', 1)
                videoPromise(video)
                gsap.to('#video_loading', 1, {
                  opacity: 0
                })
              } else {
                video.pause()
              }
              return ''
            }
          }
        }).to('#bodyPhone', {
        // 이동
          paused: true,
          scrollTrigger: {
            trigger: '#data-section-2',
            start: 'top 600px',
            end: '+=350px',
            pin: '#bodyPhone',
            scrub: true,
            anticipatePin: 1,
            onUpdate: (self) => videoUpdate(self)
          },
          x: '-=640px',
          yPercent: 57
        }).to('#bodyPhone', {
        // 두번째 영상
          scrollTrigger: {
            trigger: '#data-section-2',
            scrub: true,
            start: () => $q.screen.height >= 968 ? 'top 15%' : 'top -10px',
            end: '+=200px',
            pin: '#bodyPhone',
            onToggle: (self) => {
              if (self.isActive) {
                store.commit('setPhoneVideo', 2)
                video.load()
                videoPromise(video)
                videoPromise(video)
                gsap.to('#video_loading', 1, {
                  opacity: 0
                })
              } else {
              // video.pause()
              }
            }
          }
        }).to('#bodyPhone', {
        // 이동
          scrollTrigger: {
            trigger: '#data-section-3',
            start: 'top 600px',
            end: '+=350px',
            scrub: true,
            anticipatePin: 1,
            pin: '#bodyPhone',
            onUpdate: (self) => videoUpdate(self)
          },
          x: '+=600px',
          yPercent: 118,
          immediateRender: false
        }).to('#bodyPhone', {
        // 세번째 영상
          scrollTrigger: {
            trigger: '#data-section-3',
            scrub: true,
            start: () => $q.screen.height >= 968 ? 'top 15%' : 'top -10px',
            end: '+=200px',
            pin: '#bodyPhone',
            onToggle: (self) => {
              if (self.isActive) {
                store.commit('setPhoneVideo', 3)
                video.load()
                videoPromise(video)
                gsap.to('#video_loading', 1, {
                  opacity: 0
                })
              } else {
              // video.pause()
              }
            }
          }
        }).to('#bodyPhone', {
        // 이동
          scrollTrigger: {
            trigger: '#reviewers',
            start: () => $q.screen.height >= 968 ? 'top 900px' : 'top 720px',
            end: '+=850px',
            scrub: true,
            anticipatePin: 1,
            pin: '#bodyPhone',
            onUpdate: (self) => videoUpdate(self)
          },
          onComplete: () => {
            store.commit('setPhoneVideo', 4)
            video.load()
            videoPromise(video)
            gsap.to('#video_loading', 1, {
              opacity: 0
            })
          },
          x: '-=300px',
          yPercent: 153,
          immediateRender: false
        })
    },
    '(max-width: 1187px)': function () {
      const el = document.querySelector('#bodyPhone')
      el.style.opacity = 1
    }
  })
}
