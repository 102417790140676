export default {
  state: {
    isOpenEventDetailPopup: false,
    isOpenEventPopup: false
  },
  getters: {
    getEventDetailPopup: (state) => state.isOpenEventDetailPopup,
    getEventPopup: (state) => state.isOpenEventPopup
  },
  mutations: {
    showEventDetailPopup: (state, payload) => {
      state.isOpenEventDetailPopup = payload
    },
    showEventPopup: (state, payload) => {
      state.isOpenEventPopup = payload
    }
  }
}
