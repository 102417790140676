<template>
  <div v-if="$attrs.platform !== 'mobile'"
       id="bodySubReview"
       :class="review.layout"
       class="relative-position">
    <div id="reviewers"
         class="row full-width full-height justify-center items-center absolute"
         data-section-4>
      <div v-for="(profile, idx) in $attrs.lang.profiles"
           :key="idx"
           class="column">
        <q-btn
          :class="review.profileWrapper"
          :ripple="false"
          :tabindex="idx+8"
          class="profile column absolute"
          dense
          flat
          @click="isReviewerPopup
                  ? onToggleHandler(idx, profile.right, profile.bottom)
                  : ''">
          <review-popup
            :isShow="selection[idx]"
            :message="profile.message"
            :platform="$attrs.platform"
            :rating="profile.rating"
            class="review_popup"
          />
          <q-avatar :class="review.avatar_out"
                    class="q-mb-xs avatar"
                    size="90px">
            <q-img :class="review.profile"
                   :src="require(`@/assets/images/${profile.img}`)"
                   alt="Reviewer Profile"
                   height="100%"
                   no-spinner
                   width="100%"/>
          </q-avatar>
          <p :class="review.name"
             class="text-body_small no-padding no-margin">{{ profile.name }}</p>
        </q-btn>
      </div>
      <div id="circle_background"
           v-scroll-fire="profilesMotion"
           :class="review.circle"
           class="bg-color_vitoYellow absolute"/>
      <p :class="review.title"
         class="text-body_normal">
        {{ $attrs.lang.title }}
      </p>
      <q-btn :class="review.link"
             :ripple="false"
             class="no-padding text-color_vitoBlue text-body_normal_bold"
             dense
             flat
             tabindex="14"
             @click="appDownload('android')"
             @mousedown="logEvent('action_section2_button_google')"
             >
        {{ $attrs.lang.subtitle }}
      </q-btn>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { profilesMotion } from '@/utils/profilesMotion'
import { computed, reactive, ref, onMounted } from 'vue'
import ReviewPopup from '@/components/BodySubReviewPopup'
import { appDownload } from '../utils/appDownlaod'
import { logEvent } from '../utils/amplitude'

// Props

// Emits

// Variables

const store = useStore()
const pageMaxHeight = store.getters.getSubReviewMaxHeight + 'px'

// Responses

const reviewers = ref(null)
const right = reactive(computed(() => store.getters.getSubReviewDefaultPos.right + 'px'))
const bottom = reactive(computed(() => store.getters.getSubReviewDefaultPos.bottom + 'px'))
const isReviewerPopup = reactive(computed(() => store.getters.getIsReviewerPopup))
const selection = reactive(computed(() => store.getters.getSubReviewMessages))

// Methods

const delayAsync = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const onToggleHandler = async (idx, r, b) => {
  store.commit('restoreSubReviewMessage')
  await delayAsync(100)
  reviewers.value.forEach(reviewer => reviewer.classList.remove('on'))
  reviewers.value[idx].classList.toggle('on')
  popupOffsets(r, b)
  store.commit('setSubReviewMessage', idx)
}

const popupOffsets = (r, b) => {
  const pos = {
    bottom: b,
    right: r
  }
  store.commit('setSubReviewPopupDefaultPos', pos)
}

// Lifecycles

onMounted(() => {
  reviewers.value = document.querySelectorAll('.avatar')
  reviewers.value[2].classList.add('on')
})

</script>

<style module="review">

.layout {
  height: v-bind(pageMaxHeight);
}

.circle {
  position: absolute;
  width: 560px;
  height: 560px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 317px;
  z-index: -1;
}

.profileWrapper {
  position: absolute;
  width: 100%;
  max-width: 100px;
  padding: 0;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
}

.profile {
  max-width: 80px;
  max-height: 80px;
}

.name {
  width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  position: absolute !important;
  bottom: 66px;
}

.link {
  position: absolute !important;
  bottom: 48px;
}
</style>

<style scoped>
.review_popup {
  position: absolute;
  width: 100vw;
  max-width: 400px;
  bottom: v-bind(bottom);
  right: v-bind(right);
}

.avatar {
  width: 80px;
  height: 80px;
  background-color: #eeeeee;
}

.avatar.on {
  width: 80px;
  height: 80px;
  background-color: #FFD500;
}
</style>
