<template>
  <top-banner id="banner" />
  <div id="home"
       class="column items-center">
    <event-popup v-show="isShowEvent"
                 :lang="lang.event"
                 :platform="$attrs.platform"/>
    <event-detail-popup :lang="lang.event"
                        :platform="$attrs.platform"/>
    <top-header :lang="lang.top"
                :platform="$attrs.platform"/>
    <top-header-floating :lang="lang.top"
                         :platform="$attrs.platform"/>
    <top-header-bg/>
    <body-top-main :lang="lang.body.main"
                   :platform="$attrs.platform"/>
    <body-phone :platform="$attrs.platform"/>
    <body-sub-main :lang="lang.body.sub"
                   :platform="$attrs.platform"/>
    <features-bg :platform="$attrs.platform"/>
    <body-sub-biz :lang="lang.biz"
                  :platform="$attrs.platform"/>
    <security-pattern :platform="$attrs.platform"/>
    <footer-main :lang="lang.footer"
                 :platform="$attrs.platform"/>
  </div>
</template>

<script setup>
// Imports

import axios from 'axios'
import { useStore } from 'vuex'
import BodyPhone from '@/components/BodyPhone'
import TopHeader from '@/components/TopHeader'
import TopBanner from '../components/TopBanner'
import BodyTopMain from '@/components/BodyTopMain'
import BodySubMain from '@/components/BodySubMain'
import FooterMain from '@/components/FooterMain'
import BodySubBiz from '@/components/BodySubBiz'
import EventPopup from '@/components/EventPopup'
import TopHeaderFloating from '@/components/TopHeaderFloating'
import TopHeaderBg from '@/components/TopHeaderBg'
import FeaturesBg from '@/components/FeaturesBg'
import SecurityPattern from '@/components/SecurityPattern'
import { onMounted, defineAsyncComponent, reactive, computed } from 'vue'
import { delayAsync } from '@/utils/delayAsync'
import { logEvent } from '../utils/amplitude'

// Props

// Emits

// Variables

const store = useStore()
const lang = store.getters.getLang
const isShowEvent = reactive(computed(() => store.getters.getEventPopup))
const eventDetailPopup = defineAsyncComponent({
  loader: () => import('@/components/EventDetailPopup')
})

// Responses

// Watches

// Methods

// Lifecycles

onMounted(async () => {
  try {
    const event = await axios.get(`${process.env.VUE_APP_EVENT_URL}`, { timeout: 5000 })
    const eventNotice = event.data.noticeContents[0]
    const checkEventNoticeID = eventNotice.id.toString()
    const checkEventStorage = localStorage.getItem('eventPopup')

    if (eventNotice.brand_web && checkEventNoticeID !== checkEventStorage) {
      store.commit('setEvent', eventNotice)
      await delayAsync(2000)
      if (window.scrollY > 600) return ''
      store.commit('showEventPopup', true)
    }
  } catch (e) {
    console.error('이벤트 에러', e)
  }
  logEvent('page_vito_main')
})
</script>
