<template>
  <div v-show="$attrs.platform === 'desktop'"
       id="bodyPhone"
       class="row absolute justify-center items-center bodyPhone">
    <img :class="phone.overlap"
         src="@/assets/images/img_phone_device_layered.png"/>
    <div :class="phone.device">
      <q-img :class="phone.device"
             class="absolute"
             height="100%"
             no-spinner
             src="@/assets/images/img_phone_device.png"
             width="100%"/>
    </div>
    <div class="row absolute justify-center items-center">
      <div id="video_loading"
           :class="phone.loading"/>
      <video id="vid_phone_case"
             class="vid_phone_case q-mt-sm"
             height="583"
             loop
             muted
             playsinline>
        <source :src="currentPhoneVideo"
                type="video/mp4"/>
      </video>
      <div :class="phone.bg"/>
    </div>
  </div>
</template>

<script setup>
// Imports

import { useQuasar } from 'quasar'
import { useStore } from 'vuex'
import { movePhone } from '@/utils/whenisUseful'
import { computed, reactive, onMounted } from 'vue'

// Props

// Emits

// Variables

const $q = useQuasar()
const store = useStore()

// Responses

const pageWidth = ($q.screen.width / 2) + 100 + 'px'
const currentPhoneVideo = reactive(computed(() => require(`@/assets/video/vid_phone_case${store.getters.getCurrentPhoneVideo}.mp4`)))

// Methods

// Watch

// Lifecycles
onMounted(() => movePhone())

</script>

<style module="phone">

.overlap {
  position: absolute;
  width: 100vw;
  max-width: 474px;
  mix-blend-mode: darken;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}

.device {
  width: 474px;
  height: 770px;
  z-index: 3;
}

.loading {
  position: absolute;
  width: 320px;
  height: 620px;
  background-color: white;
  opacity: 0;
  border-radius: 24px;
  z-index: 1;
}

.bg {
  position: absolute;
  width: 330px;
  height: 620px;
  background-color: white;
  opacity: 1;
  border-radius: 24px;
  z-index: -1;
}

</style>

<style>
.bodyPhone {
  top: 1600px;
  left: v-bind(pageWidth);
  z-index: 5;
}
</style>
