<template>
  <div id="topHeaderFloating"
       :class="$attrs.platform === 'mobile'
        ? floatingTopHeader.mobile
        : floatingTopHeader.desktop"
       class="row items-center">
    <q-toolbar class="row full-width full-height no-padding">
      <div :class="$attrs.platform !== 'mobile'
            ? 'col-8'
            : 'col-4'"
           class="row items-center z-top">
        <q-btn :ripple="false"
               dense
               flat
               tabindex="1"
               @click="anchorToTop">
          <img :class="floatingTopHeader.logo"
               src="@/assets/images/img_top_logo.png"/>
        </q-btn>
        <p v-if="$attrs.platform !== 'mobile'"
           :style="[$attrs.platform === 'mobile'
            ? {'paddingTop': '1px' }
            : { 'paddingTop': '3px'}]"
           class="text-caption_large q-pl-sm q-mb-xs">
          {{ $attrs.lang.slogan }}
        </p>
      </div>
      <div :class="$attrs.platform !== 'mobile'
            ? 'col-4'
            : 'col-8'"
           class="row items-center z-max">
        <q-space/>
        <q-btn dense
               flat
               class="no-padding"
               :class="$attrs.platform === 'mobile'
               ? ''
               : ''"
               :ripple="false"
               @click="openURL(`${devURL}/?utm_source=vito_homepage&utm_medium=무료_click&utm_campaign=family_site_상단_api`)">
          <p class="no-margin"
             :class="$attrs.platform === 'mobile'
             ? 'text-caption_large q-pr-md'
             : 'text-body_small'"
          >
            {{ $attrs.lang.nav[1].name }}
          </p>
        </q-btn>
        <q-separator v-if="$attrs.platform !== 'mobile'" vertical inset class="q-mx-lg"/>
        <q-btn v-if="$attrs.platform !== 'mobile'"
               dense
               flat
               class="no-padding q-mr-lg"
               :ripple="false"
               @click="openURL(webURL)">
          <p class="no-padding no-margin text-body_small">
            {{ $attrs.lang.nav[0].name }}
          </p>
        </q-btn>
        <q-btn
            :ripple="false"
            class="download bg-color_black text-color_white"
            dense
            flat
            @click="appDownload('android')">
          <p :class="$attrs.platform === 'mobile'
              ? 'text-caption_normal'
              : 'text-caption_large'"
             class="no-margin">
            {{ $attrs.lang.appDownload }}
          </p>
        </q-btn>
      </div>
    </q-toolbar>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { scroll, openURL } from 'quasar'
import { topHeader } from '@/utils/topHeader'
import { onMounted } from 'vue'
import { appDownload } from '@/utils/appDownlaod'

// Props

// Emits

// Variables

const store = useStore()
const { getScrollTarget, setVerticalScrollPosition } = scroll
const pageMinWidth = store.getters.getPageMinWidth + 'px'
const pageMaxWidth = store.getters.getPageMaxWidth + 'px'
const headerMaxHeight = store.getters.getTopMaxHeight + 'px'
const webURL = store.getters.getWebURL
const devURL = store.getters.getDevURL

// Responses

// Methods

const anchorToTop = () => {
  const target = document.getElementById('topHeaderFloating')
  const getScroll = getScrollTarget(target)
  const offset = target.offsetTop
  const duration = 500
  setVerticalScrollPosition(getScroll, offset, duration)
}

// Lifecycles

onMounted(() => topHeader())

</script>

<style module="floatingTopHeader">

.desktop {
  visibility: hidden;
  position: fixed;
  top: -60px;
  width: 100%;
  padding: 0 24px;
  height: v-bind(headerMaxHeight);
  min-width: v-bind(pageMinWidth);
  max-width: v-bind(pageMaxWidth);
  max-height: v-bind(headerMaxHeight);
  z-index: 99;
}

.mobile {
  visibility: hidden;
  position: fixed;
  top: -60px;
  width: 100%;
  padding: 0 24px;
  overflow-x: visible;
  height: v-bind(headerMaxHeight);
  min-width: v-bind(pageMinWidth);
  max-width: v-bind(pageMaxWidth);
  max-height: v-bind(headerMaxHeight);
  z-index: 99;
}

.logo {
  width: 100%;
  max-width: 60px;
}

</style>

<style scoped>
.download {
  border: 1px solid rgba(0, 0, 0, .05);
  padding: 4px 12px;
  border-radius: 24px;
  opacity: 1;
  z-index: 12;
}
</style>
