<template>
  <div id="app">
    <router-view :platform="getPlatform"/>
  </div>
</template>

<script setup>
// Imports

import { useQuasar } from 'quasar'
import { useStore } from 'vuex'
import { onMounted, ref, watch, reactive, computed } from 'vue'
import { checkPlatform } from '@/utils/checkPlatform'

// Props

// Emits

// Variables

const $q = useQuasar()
const store = useStore()

// Responses
const getPlatform = ref('')
const browserWidth = reactive(computed(() => $q.screen.width))

// Methods

// Watches

watch(browserWidth, () => {
  if (browserWidth.value < 1188) {
    getPlatform.value = 'mobile'
    store.commit('setPlatform', 'mobile')
    return ''
  }
  getPlatform.value = 'desktop'
  store.commit('setPlatform', 'desktop')
})

// Lifecycles

onMounted(() => {
  store.commit('setPageWidth', browserWidth)
  getPlatform.value = checkPlatform()
  store.commit('setPlatform', getPlatform.value)
})

</script>
<style module="app">
</style>
