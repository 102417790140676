import { createStore } from 'vuex'
import event from '@/store/modules/event'
import inits from '@/store/modules/inits'
import top from '@/store/modules/top'
import body from '@/store/modules/body'
import footer from '@/store/modules/footer'
import phone from '@/store/modules/phone'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    event,
    inits,
    top,
    body,
    footer,
    phone
  }
})
