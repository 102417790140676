import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'

export const floatingBanner = () => {
  gsap.registerPlugin(ScrollTrigger)
  return gsap.to('.floating-banner', {
    scrollTrigger: {
      trigger: '#bodySubIntroduction',
      start: '-240px',
      end: '+=140px',
      scrub: true
    },
    y: -160,
    opacity: 1
  })
}
