import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'

const tl = gsap.timeline()

gsap.registerPlugin(ScrollTrigger)

export const paperMoveUp = () => {
  return tl.from('#bizPaper', {
    paused: true,
    y: '+=50px'
  })
    .to('#bizPaper', {
      scrollTrigger: {
        trigger: '#bodySubBiz',
        start: 'top 80%',
        end: '+=300px',
        scrub: true
      },
      y: '-=50px'
    })
}
