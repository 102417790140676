<template>
  <div id="bodySubBiz"
       :class="$attrs.platform === 'mobile'
        ? biz.mobile
        : biz.desktop"
       class="column justify-end items-center bg-color_darkBlue relative-position">
    <div id="bizPaper"
         :class="$attrs.platform === 'mobile'
         ? biz.paperMobile
         : biz.paperDesktop"
         class="column items-center">
      <div :class="$attrs.platform === 'mobile'
           ? biz.imgMobile
           : biz.imgDesktop">
        <q-img no-spinner
               src="@/assets/images/img_body_biz_link.png"/>
      </div>
      <div class="column full-width items-center">
        <p :class="$attrs.platform === 'mobile'
              ? 'text-body_normal_bold text-center q-px-xl'
              : 'text-title_normal_bold'"
           class="text-color_black text-center" v-html="$attrs.platform === 'mobile' ? $attrs.lang.titleMobile : $attrs.lang.title">
        </p>
        <p :class="$attrs.platform === 'mobile'
            ? 'text-caption_large q-px-md'
            : 'text-body_normal'"
           class="text-center subTitle q-mb-xl"
           v-html="$attrs.lang.subTitle"/>
        <q-btn
            :href="`mailto:${bizEmail}`"
            :ripple="false"
            class="email row text-color_white bg-color_vitoBlue"
            flat
            size="xs"
            @mousedown="logEvent('action_section5_button_b2b')"
            tabindex="25">
          <q-icon
              name="email"
              size="xs"/>
          <p :class="$attrs.platform === 'mobile'
              ? 'text-body_small_bold'
              : 'text-body_small_bold'"
             class="no-margin q-pl-sm">
            {{ $attrs.lang.link }}
          </p>
        </q-btn>
        <p :class="$attrs.platform === 'mobile' ? 'text-caption_large' : 'text-body_small'"
           class="q-mb-md q-pt-xl text-color_lightgray3">
          {{ $attrs.lang.partners }}
        </p>
        <div class="row justify-center">
          <div v-for="(partner, idx) in partners"
               :key="idx"
               :class="$attrs.platform === 'mobile' ? biz.partnerMobile : biz.partnerDesktop">
            <q-img :src="require(`@/assets/images/img_body_biz_${partner}_logo.png`)"
                   :width="$q.screen.width < 350 ? '60px' : $q.screen.width > 1000 ? '150px' : '80px'"
                   no-spinner/>
          </div>
        </div>

      </div>
    </div>
    <!--    <div :class="biz.bg"/>-->
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import { computed, reactive, onMounted } from 'vue'
import { paperMoveUp } from '@/utils/biz'
import { logEvent } from '../utils/amplitude'

// Props

// Emits

// Variables

const store = useStore()

// Responses

const pageMaxWidth = reactive(computed(() => store.getters.getPageMaxWidth + 'px'))
const pageMaxHeight = reactive(computed(() => store.getters.getBizMaxHeight + 'px'))
const partners = reactive(computed(() => store.getters.getBizPartners))
const bizEmail = reactive(computed(() => store.getters.getBizEmail))

// Methods

// Lifecycles

onMounted(() => paperMoveUp())

</script>

<style module="biz">

.desktop {
  width: 100%;
  /*border-radius: 8px;*/
  /*max-width: v-bind(pageMaxWidth);*/
  height: v-bind(pageMaxHeight);
}

.mobile {
  width: 100%;
  padding: 0;
  height: 440px;
}

.imgDesktop {
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
  width: 72px;
  margin-top: 6px;
  margin-right: 24px;
}

.imgMobile {
  position: absolute;
  top: -42px;
  left: 50%;
  transform: translateX(-50%);
  width: 56px;
  margin-top: 14px;
  margin-right: 24px;
}

.paperDesktop {
  width: 100%;
  height: 100%;
  max-width: v-bind(pageMaxWidth);
  max-height: v-bind(pageMaxHeight);
  padding: 80px 20px !important;
  background-color: white;
  border-radius: 8px 8px 0 0;
}

.paperMobile {
  width: 100%;
  height: 100%;
  max-width: v-bind(pageMaxWidth);
  max-height: v-bind(pageMaxHeight);
  padding: 44px 0 0;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
}

.partnerDesktop {
  width: 150px;
  margin-top: 4px;
}

.partnerMobile {
  margin-top: 4px;
}

.bg {
  position: absolute;
  height: v-bind(pageMaxHeight);
  top: 0;
  left: -50%;
  max-height: v-bind(pageMaxHeight);
  background-color: #000B28;
  z-index: -1;
}
</style>

<style scoped>
.email {
  background-color: #2E67FE;
  padding: 20px 34px !important;
  border-radius: 32px !important;
}
</style>
