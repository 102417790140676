import store from '../store'
import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'

gsap.registerPlugin(ScrollTrigger)

export const profilesMotion = () => {
  const profiles = gsap.utils.toArray('.profile')
  const reviewers = gsap.utils.toArray('.avatar')
  const axisX = [290, 400, 290, -290, -400, -290]
  const axisY = [-280, 0, 280, -280, 0, 280]
  const duration = 1.2
  const initProfile = 2
  const opacity = 1
  const ease = 'elastic.out(1, .8)'
  const stagger = () => {
    return {
      from: 'center',
      each: 0
    }
  }

  const left = (i) => i < 3 ? profiles[i].offsetLeft + axisX[i] : profiles[i].offsetLeft + axisX[i]
  const top = (i) => i < 3 ? profiles[i].offsetTop + axisY[i] : profiles[i].offsetTop + axisY[i]

  return gsap.to(profiles, {
    scrollTrigger: {
      trigger: '#circle_background',
      start: 'top 550px',
      end: '+=200px',
      toggleActions: 'restart play play reverse',
      onToggle: (self) => {
        if (!self.isActive) {
          reviewers.forEach(review => review.classList.remove('on'))
          reviewers[initProfile].classList.add('on')
          store.commit('restoreSubReviewMessage')
          store.commit('setSubReviewPopupDefaultPos', { right: 80, bottom: 100 })
        }
      }
    },
    duration,
    left: (i) => left(i),
    top: (i) => top(i),
    stagger: stagger(),
    opacity,
    ease,
    onComplete: () => {
      store.commit('restoreSubReviewMessage')
      store.commit('setSubReviewMessage', initProfile)
      store.commit('setReviewerPopup', true)
    }
  })
}
