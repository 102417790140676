export default {
  state: {
    topMaxHeight: 60,
    isFixedTopHeader: false
  },
  getters: {
    getTopMaxHeight: (state) => state.topMaxHeight,
    getFixedTopHeader: (state) => state.isFixedTopHeader
  },
  mutations: {
    setHeight: (state, h) => {
      state.height = h
    },
    setFixedTopHeader: (state, payload) => {
      state.isFixedTopHeader = payload
    }
  }
}
