<template>
  <div id="footerMenu"
       :class="$attrs.platform === 'mobile'
        ? 'column items-start q-mt-lg q-pl-lg'
        : 'row justify-center q-gutter-lg' "
       class="full-width">
    <q-btn :ripple="false"
           class="no-padding"
           dense
           flat
           tabindex="21"
           @click="openURL(companyURL)">
      <p :class="$attrs.platform === 'mobile'
          ? 'text-caption_normal_bold'
          : 'text-caption_large'"
         class="text-color_lightgray3 no-margin">
        {{ $attrs.lang[0] }}
      </p>
    </q-btn>
    <q-separator v-if="$attrs.platform !== 'mobile'"
                 color="grey-3"
                 vertical/>
    <q-btn :ripple="false"
           class="no-padding"
           dense
           flat
           tabindex="22"
           @click="openURL(recruitURL)">
      <p :class="$attrs.platform === 'mobile'
          ? 'text-caption_normal_bold'
          : 'text-caption_large'"
         class="text-color_lightgray3 no-margin">
        {{ $attrs.lang[1] }}
      </p>
    </q-btn>
    <q-separator color="grey-3"
                 vertical/>
    <q-btn :ripple="false"
           class="no-padding"
           dense
           flat
           tabindex="23"
           @click="openURL(serviceURL)">
      <p :class="$attrs.platform === 'mobile'
          ? 'text-caption_normal_bold'
          : 'text-caption_large'"
         class="text-color_lightgray3 no-margin">
        {{ $attrs.lang[2] }}
      </p>
    </q-btn>
    <q-separator color="grey-3"
                 vertical/>
    <q-btn :ripple="false"
           class="no-padding"
           dense
           flat
           tabindex="24"
           @click="openURL(privacyURL)">
      <p :class="$attrs.platform === 'mobile'
          ? 'text-caption_normal_bold'
          : 'text-caption_large'"
         class="text-color_lightgray3 no-margin">
        {{ $attrs.lang[3] }}
      </p>
    </q-btn>
    <p :class="$attrs.platform === 'mobile'
        ? 'justify-start text-caption_normal'
        : 'justify-center text-caption_large'"
       class="row full-width text-color_lightgray3 q-mt-md">
      ⓒ ReturnZero Inc.
    </p>
  </div>
</template>

<script setup>
// Imports

import { openURL } from 'quasar'
import { useStore } from 'vuex'

// Props

// Emits

// Variables

const store = useStore()
const recruitURL = store.getters.getRecruitsURL
const companyURL = store.getters.getCompanyURL
const serviceURL = store.getters.getServiceURL
const privacyURL = store.getters.getPrivacyURL

// Responses

// Methods

// Lifecycles

</script>

<style module="bottomMenu">
</style>
