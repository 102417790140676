<template>
  <div id="bodyTopMain"
       :class="$attrs.platform === 'mobile'
        ? bodyTopMain.mobile
        : bodyTopMain.desktop"
       class="row full-width relative-position hide-scrollbar">
    <div :class="$attrs.platform === 'mobile'
          ? 'column'
          : 'row'"
         class="full-width full-height justify-center">
      <div :class="$attrs.platform === 'mobile'
            ? 'col-9'
            : 'col-6'"
      >
        <body-top-main-slogan :lang="$attrs.lang"
                              :platform="$attrs.platform"/>
      </div>
      <div :class="$attrs.platform === 'mobile'
            ? 'col-3 justify-center'
            : 'col-6 justify-end'"
           class="row">
        <body-top-main-app-download :lang="$attrs.lang"
                                    :platform="$attrs.platform"/>
      </div>
    </div>
    <body-user-cases :lang="$attrs.lang"
                     :platform="$attrs.platform"/>
  </div>
</template>

<script setup>
// Imports

import { useStore } from 'vuex'
import BodyUserCases from '@/components/BodyTopMainUserCases'
import BodyTopMainSlogan from '@/components/BodyTopMainSlogan'
import BodyTopMainAppDownload from '@/components/BodyTopMainAppDownload'

// Props

// Emits

// Variables

const store = useStore()
const pageMaxWidth = store.getters.getPageMaxWidth + 'px'
const pageMaxHeight = store.getters.getPageMaxHeight + 'px'

// Responses

// Methods

// Lifecycles

</script>
<style module="bodyTopMain">
.desktop {
  width: 100%;
  max-width: v-bind(pageMaxWidth);
  height: v-bind(pageMaxHeight);
  max-height: v-bind(pageMaxHeight);
  margin-top: 60px;
  padding: 0;
}

.mobile {
  width: 100%;
  max-width: v-bind(pageMaxWidth);
  height: v-bind(pageMaxHeight);
  max-height: v-bind(pageMaxHeight);
  margin-top: 32px;
  padding: 0 24px;
  overflow-x: hidden;
}

</style>
