import { gsap } from '@/assets/modules/gsap.min'
import { ScrollTrigger } from '@/assets/modules/ScrollTrigger.min'

gsap.registerPlugin(ScrollTrigger)

export const videoHandler = (c, v) => {
  const video = gsap.utils.toArray(`.${v}`)
  gsap.utils.toArray(`.${c}`).forEach((n, idx) => {
    gsap.to(n, {
      scrollTrigger: {
        trigger: video[idx],
        start: 'top center',
        end: '+=550px',
        scrub: true,
        toggleActions: 'play pause resume reset',
        onToggle: (self) => {
          if (self.isActive) {
            video[idx].play()
          } else {
            video[idx].pause()
          }
          return ''
        }
      }
    })
  })
}

export const moveUP = (el, trigger) => {
  gsap.registerPlugin(ScrollTrigger)
  el.forEach((e, i) => {
    gsap.to(e, {
      duration: 2,
      scrollTrigger: {
        trigger: trigger[i],
        start: 'top 70%',
        end: '+=100px',
        toggleActions: 'restart pause reverse reset'
      },
      y: -50
    })
  })
}
